<template>
  <ul>
    <component
        :is="resolveNavItemComponent(item)"
        v-for="item in menus"
        :key="item.header || item.title"
        :item="item"
    />
  </ul>
</template>

<script>
import {resolveVerticalNavMenuItemComponent as resolveNavItemComponent} from '@core/layouts/utils'
import {provide, ref} from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    provide('openGroups', ref([]))
    let menus = props.items

    const roleData = JSON.parse(localStorage.getItem('roleData'))
    if (roleData.role_type === 1) {
      menus = menus.filter(item => item.header === 'STUDENT MANAGEMENT' || item.route === 'student-list')
    }

    return {
      menus,
      resolveNavItemComponent,
    }
  },
}
</script>
