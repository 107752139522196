export default [
    {
        header: 'GENERAL',
    },
    {
        title: 'Dashboard',
        route: 'home',
        icon: 'HomeIcon',
    },
    {
        header: 'CONTENT MANAGEMENT',
    },
    {
        title: 'Categories',
        route: 'cms-category-list',
        icon: 'GridIcon',
    },
    {
        title: 'Content',
        route: 'cms-content-list',
        icon: 'FileTextIcon',
    },
    {
        title: 'Menu',
        route: 'cms-menu-list',
        icon: 'MenuIcon',
    },
    {
        title: 'Sliders',
        route: 'cms-slider-list',
        icon: 'BookIcon',
    },
    {
        title: 'Language',
        route: 'cms-language-list',
        icon: 'GlobeIcon',
    },
    {
        title: 'Settings',
        route: 'cms-settings',
        icon: 'SettingsIcon',
    },
    {
        header: 'STUDENT MANAGEMENT',
    },
    {
        route: 'student-list',
        title: 'Students',
        icon: 'LayersIcon',
    },
    {
        route: 'university-list',
        title: 'Universities',
        icon: 'BookIcon',
    },
    {
        route: 'partner-list',
        title: 'Partners',
        icon: 'SidebarIcon',
    },
    {
        header: 'SYSTEM MANAGEMENT',
    },
    {
        route: 'system-mail-list',
        title: 'Mail',
        icon: 'MailIcon',
    },
    {
        route: 'system-role-list',
        title: 'Roles',
        icon: 'LockIcon',
    },
    {
        route: 'system-user-list',
        title: 'Users',
        icon: 'UsersIcon',
    },
]
